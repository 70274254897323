import React, { Component } from "react";
import styled from "styled-components";
import IntlMessages from "../../../util/IntlMessages";
import TextField from "@material-ui/core/TextField";
const Wrapper = styled.div`
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

class AutoCompleteField extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.value = props.value;
        this.clearSearchBox = this.clearSearchBox.bind(this);
    }

    initAutocomplete() {
        const { map, mapApi } = this.props;
        const options = {
            // restrict your search to a specific type of result
            // types: ['geocode', 'address', 'establishment', '(regions)', '(cities)'],
            // restrict your search to a specific country, or an array of countries
            // componentRestrictions: { country: ['gb', 'us'] },
        };
        this.autoComplete = new mapApi.places.Autocomplete(this.searchInput, options);
        this.autoComplete.addListener("place_changed", this.onPlaceChanged);
        this.autoComplete.bindTo("bounds", map);
    }

    componentWillUnmount({ mapApi } = this.props) {
        mapApi.event.clearInstanceListeners(this.searchInput);
    }

    onPlaceChanged = ({ map, addplace } = this.props) => {
        const place = this.autoComplete.getPlace();

        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        if (addplace) addplace(place);

        this.searchInput.blur();
    };

    clearSearchBox() {
        this.searchInput.value = "";
    }

    capitalize(s) {
        if (typeof s !== "string") return "";
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    isInitialized = false;
    textChanged(s) {
        this.value = s;
        if (s.length > 4 && !this.isInitialized) {
            this.initAutocomplete();
            this.isInitialized = true;
        }
    }

    componentDidUpdate(props) {
        this.searchInput.value = props.value;
    }

    render() {
        return (
            <Wrapper>
                <TextField
                    margin="dense"
                    id={this.props.field.name}
                    name={this.props.field.name}
                    label={this.props.label}
                    placeholder={this.props.field.placeholder}
                    /* value={this.props.value ? this.props.value : ""}*/
                    fullWidth
                    variant={this.props.variant}
                    onChange={(ev) => this.textChanged(ev.target.value)}
                    inputRef={(ref) => {
                        this.searchInput = ref;
                    }}
                    disabled={this.props.disabled}
                    value={this.props.value}
                />
            </Wrapper>
        );
    }
}

export default AutoCompleteField;
