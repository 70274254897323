import { decorate, observable, action, runInAction } from 'mobx';
import WebApi, { getErrorMessage, getValidationErrors } from 'modules/WebApi';
//import { StoreInterface, RootStoreInterface } from 'interfaces';
import CommonStore from "modules/CommonStore";
import TariffsAPI from "./api"

class tariffsStore extends CommonStore {

    changingError = "";
    isChangingInProgress = "";

  constructor(rootStore: RootStoreInterface) {
    const apiClient = new TariffsAPI(rootStore.apiClient);

    super(
        rootStore,
        apiClient,
        rootStore.specifications["Tariffs"]
    );
  }

    async calculateEstimate(id, record) {
        runInAction(() => {
            this.changingError = "";
            this.isChangingInProgress = false;
        });

        try {
            const data = await this.apiClient.calculateEstimate(id, record);
            this.dispatch(data, "update");
            runInAction(() => {
                this.isChangingInProgress = false;
            });
            return data;
        } catch (err) {
            runInAction(() => {
                this.changingError = err;
                this.isChangingInProgress = false;
            });
        }
    }



}


decorate(tariffsStore, {
    changingError: observable,
    isChangingInProgress: observable,
    calculateEstimate: action,
});



export default tariffsStore;
